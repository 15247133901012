import React, { useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';

const socket = io("wss://xyz.ngrok.io");  // Replace with your actual ngrok URL

const VideoPlayer = () => {
    const videoRef = useRef(null);
    const [videoUrl, setVideoUrl] = useState('');

    useEffect(() => {
        // Listen for play video event
        socket.on('play_video', (data) => {
            setVideoUrl(data.video_url);
            if (videoRef.current) {
                videoRef.current.src = data.video_url;
                videoRef.current.play();
            }
        });

        // Listen for stop video event
        socket.on('stop_video', () => {
            if (videoRef.current) {
                videoRef.current.pause();
                videoRef.current.src = "";
            }
        });

        // Clean up the socket connection when the component unmounts
        return () => {
            socket.off('play_video');
            socket.off('stop_video');
        };
    }, []);

    return (
        <div>
            <h1>Honeypack Video Player</h1>
            <video ref={videoRef} width="800" height="450" controls />
        </div>
    );
};

export default VideoPlayer;
