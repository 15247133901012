import React from 'react';
import VideoPlayer from './components/VideoPlayer';

function App() {
    return (
        <div className="App">
            <VideoPlayer />
        </div>
    );
}

export default App;
